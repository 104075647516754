import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { BarChart } from '@mui/x-charts/BarChart';

export default function RDEventMethod(props) {

    const [displayData, setDisplayData] = useState([{title: "Training Events", count: 0}, {title: "Leaders Trained", count: 0}]);


    return(
        <Paper>
            <Box p={1}>
                <Box sx={{height:'50px'}}>
                    <Typography variant="h4" component="h1" sx={{color:"grey"}} gutterBottom>
                        Events by Method
                    </Typography>
                </Box>
                <Box>
                    <BarChart
                        xAxis={[
                            {
                            id: 'barCategories',
                            data: ['2022', '2023', '2024', '2025'],
                            scaleType: 'band',
                            },
                        ]}
                        series={
                            [{ data: [25, 10, 10, 30], label:'In-Person' }, { data: [12, 15, 20, 22], label:'Online' }, { data: [0, 8, 13, 12], label:'Hybrid' }]
                        }
                        height={300}
                    />
                </Box>
            </Box>
        </Paper>
    );
}