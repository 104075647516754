import React from 'react';
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';

export default function Home(props) {

  return (
    <Grid container mt={4}>
      <Grid item xs={0} sm={1} md={3}></Grid>
        <Grid item xs={12} sm={10} md={6}>

          <Box>
            <Typography variant="h4" color="textSecondary" gutterBottom>Transform Your Leadership</Typography>
          </Box>
          <Box>
            <Typography variant="h5" color="textSecondary">A leadership training experience where you will learn the Eight Core Values of the most effective Christian leaders. Transform your leadership and discover how you can&nbsp;change&nbsp;history.</Typography> 
          </Box>

          <Box mt={3}>
            <Button variant="contained" color="primary" href="/user/register" startIcon={<PersonIcon />}>Create an Account</Button>
          </Box>

          <Box sx={{display: "flex", flexDirection:"row", justifyContent: "center", alignItems: "center", width:"100%", backgroundColor: "black", borderRadius: "5px"}} mt={3}>
            <Box sx={{aspectRatio: "16/9", height:"100%", width:"100%"}}>
              <iframe width="100%" height="100%" loading="lazy" src="https://www.youtube.com/embed/fOOfch7GKsY?si=Np3G1sbNqfxfdLMr" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Box>
          </Box>

          <Box mt={3}>
            <img
            width="100%"
            src={`${process.env.PUBLIC_URL}/EightCoreValues.jpg`}
            alt="eight core values" />
          </Box>

          <Box mt={3}>
            <Typography variant="h5" color="textSecondary">Change history by accelerating the spread of the Gospel and become a leader of leaders empowered by the Holy Spirit through ILI’s Eight&nbsp;Core&nbsp;Values.</Typography>
          </Box>
     

          </Grid>
        <Grid item xs={0} sm={1} md={3}></Grid>
    </Grid>
  );
}
