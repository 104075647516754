import React, {useEffect, useState} from  'react';
import SophConstants from '../../../../constants/SophConstants';
import RDTopNumbers from './Elements/RDTopNumbers';
import RDDateRange from './Elements/RDDateRange';
import RDTopNumbersLong from './Elements/RDTopNumbersLong';
import RDEventTypeGraph from './Elements/RDEventTypeGraph';
import RDLdrTrainedMethod from './Elements/RDLdrTrainedMethod';
import RDEventsMethod from './Elements/RDEventsMethod';
import RDLeadersTypeGraph from './Elements/RDLeadersTypeGraph';
import RDETeams from './Elements/RDETeams';
import RDCountries from './Elements/RDCountries';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

export default function RDashAnalytics(props) {
    const{groupID} = props;

    //first day this year
    const firstDay = new Date(new Date().getFullYear(), 0, 1);
    //first day 5 years ago
    const firstDay5YearsAgo = new Date(new Date().getFullYear(), 0, 1);

    //last day this year
    const lastDay = new Date(new Date().getFullYear(), 11, 31);

    const [query, setQuery] = useState({groupID: groupID, startDate: firstDay, endDate: lastDay});

    return (
        <Box sx={{display:'flex', flexDirection:'column', bgcolor: '#eeeeee', p: 1, gap: 1}}>
            <Box>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <RDTopNumbersLong />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <RDDateRange filterData={{startDate: firstDay, endDate: lastDay}} />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={1}>
                    <Grid item sm={12} md={6}>
                        <RDEventTypeGraph />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <RDLeadersTypeGraph />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={1}>
                    <Grid item sm={12} md={6}>
                        <RDEventsMethod />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <RDLdrTrainedMethod />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={1}>
                    <Grid item sm={12} md={6}>
                        <RDETeams />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <RDCountries />
                    </Grid>
                </Grid>
            </Box>

        </Box>
    );



}