import React, {useEffect, useState} from  'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ManageTabs from './ManageTabs';
import CheckInOverview from './CheckInOverview';
import ReportOverview from './ReportOverview';
import CertificateOverview from './CertificateOverview';
import EventNameCard from './EventNameCard';


export default function ManageOverview(props) {
    const { eventData, isLoading, updateInformation } = props;

    const [tabName, setTabName] = useState({currentTab: 0});

    function changeTabCallback(newTabName) {
        setTabName({currentTab: newTabName});
    }

    function DispalyTab(value) {
        const { tabValue } = value;

        switch(tabValue) {
            case 0:
                return <CheckInOverview eventData={eventData} isLoading={isLoading} updateInformation={updateInformation}/>;
            case 1:
                return <ReportOverview eventData={eventData} isLoading={isLoading} updateInformation={updateInformation}/>;
            case 2:
                return <CertificateOverview eventData={eventData} isLoading={isLoading} updateInformation={updateInformation}/>;
            default:
                return <CheckInOverview eventData={eventData} isLoading={isLoading} updateInformation={updateInformation} />;
        }
    }

    return (
        <Grid container spacin={2}>
            <Grid item xs={12}>
                <EventNameCard isLoaded={!isLoading} eventData={eventData}/>
            </Grid>

            <Grid item xs={12}>
                <ManageTabs changeTabCallback={changeTabCallback}/>
            </Grid>
            <Grid item xs={12}>
                <DispalyTab tabValue={tabName.currentTab}/>
            </Grid>
        </Grid>
    );
}