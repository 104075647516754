import React, {useState, useEffect} from 'react';
import {AldersgateContext} from '../../../../../context/aldersgate';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export default function RDTopNumbersLong(props) {
    const {filterData} = props;
    const { groupID, startingDate, endingDate } = props;

    const [displayData, setDisplayData] = useState([{title: "Training Events", count: 0}, {title: "Leaders Trained", count: 0}]);

    const aldersgateContext = React.useContext(AldersgateContext);

    useEffect(() => {
        GetEventsCount();
    }, [groupID, startingDate, endingDate]);


    const GetEventsCount = () => {
        if(groupID === undefined || groupID === null)
            return;

        let query = '&groupID=' + groupID;

        if(startingDate !== undefined && startingDate !== null)
            query += '&startingDate=' + startingDate.toISOString();

        if(endingDate !== undefined && endingDate !== null)
            query += '&endingDate=' + endingDate.toISOString();
        
        aldersgateContext.GetFromAPI('stats/participantcount', query, 1).then((success, err) => {
            if(success) {

                setDisplayData( prev => {
                    return [
                        {title: "Training Events", count: 0 || 0},
                        {title: "Leaders Trained", count: success?.count || 0}
                    ]
                    }
                )
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    //first day this year
    const firstDay = new Date(new Date().getFullYear(), 0, 1);
    //first day 5 years ago
    const firstDay5YearsAgo = new Date(new Date().getFullYear(), 0, 1);

    //last day this year
    const lastDay = new Date(new Date().getFullYear(), 11, 31);

    return(
        <Paper sx={{display:'flex', flexDirection:'column', width:'100%', height:'100%'}}>
            <Box p={1}>
                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', alignContent:'center', alignItems:'center', width:'100%', height:'100px'}}>
                    {displayData.map((item, index) => (
                        <Box key={index}>
                            <Typography variant="h5" component="h6" color="info" sx={{color:"grey"}} gutterBottom>
                                {item.title}
                            </Typography>
                            <Typography variant="h3" component="h4" color="info" sx={{color:"grey"}} gutterBottom>
                                {item.count}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Paper>
    );
}