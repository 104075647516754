import React, { useState, useEffect } from 'react';
import RDCountryListRow from './RDCountryListRow';
import RDCountryListCard from './RDCountryListCard';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function RDCountryListItem(props) {
    const { rowData } = props;

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box>
            {smMatches ? (<RDCountryListRow key={rowData.adID} rowItemData={rowData} />)
                : (<RDCountryListCard key={rowData.adID} rowItemData={rowData} />)
            }
        </Box>
    );
}