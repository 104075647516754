import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { BarChart } from '@mui/x-charts/BarChart';

export default function RDEventTypeGraph(props) {

    const [displayData, setDisplayData] = useState([{title: "Training Events", count: 0}, {title: "Leaders Trained", count: 0}]);


    return(
        <Paper>
            <Box p={1}>
                <Box sx={{height:'50px'}}>
                    <Typography variant="h4" component="h1" sx={{color:"grey"}} gutterBottom>
                        Event Types
                    </Typography>
                </Box>
                <Box>
                    <BarChart
                        xAxis={[
                            {
                            id: 'barCategories',
                            data: ['HMJ', 'Module 1', 'Module 2', 'Summit'],
                            scaleType: 'band',
                            },
                        ]}
                        series={[
                            {
                            data: [20, 5, 3, 1],
                            },
                        ]}
                        height={300}
                    />
                </Box>
            </Box>
        </Paper>
    );
}