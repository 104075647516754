import React, {useEffect, useState, useContext} from  'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TranslationText from '../../TranslationText';
import TranslationContainer from '../../../localization/TranslationContainer';

export default function AccountUpgrade(props) {

    const translationContainer = new TranslationContainer();
    
    return(
        <Box sx={{display:'flex', flexDirection:'column', height:'100%'}}>
            <Paper>
                <Box p={1}>
                    <Box>
                        <Typography variant="h4" component="h1" sx={{color:"grey"}} gutterBottom>
                            <TranslationText page='tables' dbKey='verifyacc-title'>Looking to Train Leaders?</TranslationText>
                        </Typography>
                    </Box>
                    <Box sx={{display:'flex', flexDirection:'column', alignContent:'center', alignItems:'center', justifyContent:'center'}}>
                        <Box>
                            <Typography variant="h5" component="h6" color="info" sx={{color:"grey"}} gutterBottom>
                                <div dangerouslySetInnerHTML={{ __html:translationContainer.GetTranslation('tables', 'verifyacc-text1', 'To access leadership training features and resources, you must be a verified ILI Leader. To be verified, email us at <a href="mailto:micah@iliteam.org">micah@ILITeam.org</a> with your name, email, and which training event (city, country, dates) you attended.')}} />
                                </Typography>
                            <Typography variant="h5" component="h6" color="info" sx={{color:"grey"}} gutterBottom>
                                <TranslationText page='tables' dbKey='verifyacc-text2'>Once confirmed, you will have access to the training features of this site.</TranslationText>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}