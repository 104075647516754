import React, { useState, useEffect } from 'react';
import AEventsView from '../Events/AEventsView';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export default function EventsDisplay(props) {

    console.log('EventsDisplay: ', JSON.stringify(props, null, 2));

    const {user} = props;

    const [userData, setUserData] = useState(null);
    const [query, setQuery] = useState('');

    useEffect(() => {
        setUserData(user);
    }, [user]);

    useEffect(() => {
        if(userData != null) {        
            let queryBuilder = '&queryLimit=400';
            queryBuilder += '&ownerID=' + userData.adID;

            setQuery(queryBuilder);

        }
    }, [userData]);

    return (
        <Box sx={{overflowY: 'auto', height: 'auto', maxHeight: '80vh', p: 2}}>
            {userData != null ? (
                <AEventsView searchQuery={query} />
            ) : (
                <Paper>
                    <Typography variant="h6" component="h1" gutterBottom>
                        Select a user to view their events.
                    </Typography>
                </Paper>
            )}
        </Box>
    );
}