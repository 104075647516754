import React, {useEffect, useState, useContext} from  'react';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import FAnnouncementElement from '../../FeedElements/FAnnouncementElement';
import FVideoElement from '../../FeedElements/FVideoElement';
import FArticleElement from '../../FeedElements/FArticleElement';
import FCallInviteElement from '../../FeedElements/FCallInviteElement';
import FPodcastElement from '../../FeedElements/FPodcastElement';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export default function HomeFeed(props) {
    const { feed } = props;

    const [feedItems, setFeedItems] = useState([]);

    useEffect(() => {
        if(feed) {
            setFeedItems(SortFeedItems(feed));
        }
    }, [feed]);

    const SortFeedItems = (feedItems) => {
        let priorityItems = [];
        let normalItems = [];

        feedItems.forEach((item) => {
            let today = new Date();
            //7 days prior
            let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

            if(item.priority < 50 || new Date(item.date) < lastWeek) {
                priorityItems.push(item);
            } else {
                normalItems.push(item);
            }
        });

        priorityItems.sort((a, b) => {
            return a.priority - b.priority;
        });

        normalItems.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });

        return priorityItems.concat(normalItems);
    }

    const BuildFeed = (feedData) => {
        let feed = [];

        if(!feedData) {
            return null;
        }

        let elementID = 0;
        feedData.forEach((item) => {
            switch(item.type) {
                case 'announcement':
                    feed.push(<FAnnouncementElement key={elementID} elementData={item} />);
                    break;
                // case 'event':
                //     feed.push(EventElement(item));
                //     break;
                case 'video':
                    feed.push(<FVideoElement key={elementID} elementData={item} />);
                    break;
                // case 'image':
                //     feed.push(ImageElement(item));
                //     break;
                case 'callinvite':
                    feed.push(<FCallInviteElement key={elementID} elementData={item} />);
                    break;
                case 'article':
                    feed.push(<FArticleElement key={elementID} elementData={item} />);
                    break;
                case 'podcast':
                    feed.push(<FPodcastElement key={elementID} elementData={item} />);
                default:
                    break;
            }
            elementID++;
        });

        return feed;
    }

    //get initials
    const GetInitials = (fName, lName) => {
        let initials = fName.charAt(0) + lName.charAt(0);
        return initials.toUpperCase();
    }

    //get color based on initials
    const GetInitialsColor = (initials) => {
        let color = '#000000';

        // Generate a pseudo-random number based on two letters of the alphabet
        let charCode1 = initials.charCodeAt(0); // Convert first letter to char code (A = 0, B = 1, etc.)
        let charCode2 = initials.charCodeAt(1); // Convert second letter to char code (A = 0, B = 1, etc.)

        //make sure they arent zero or nan
        if(charCode1 === 0 || isNaN(charCode1)) {
            charCode1 = 1;
        }

        if(charCode2 === 0 || isNaN(charCode2)) {
            charCode2 = charCode1 * 15;
        }

        let randomNumber1 = (charCode1 * 26 + charCode2) % 200; // Generate a number between 0 and 255
        let randomNumber2 = (charCode2 * 11 + charCode1) % 200; // Generate a number between 0 and 255
        let randomNumber3 = (charCode1 * charCode2) % 200; // Generate a number between 0 and 255

        //convert to hex
        let hex1 = randomNumber1.toString(16);
        let hex2 = randomNumber2.toString(16);
        let hex3 = randomNumber3.toString(16);

        if(hex1.length === 1)
            hex1 = "0" + hex1;
        if(hex2.length === 1)
            hex2 = "0" + hex2;
        if(hex3.length === 1)
            hex3 = "0" + hex3;

        color = '#' + hex1 + hex2 + hex3;

        return color;
    }

    //pretty date format
    const GetPrettyDate = (date) => {
        let prettyDate = "";

        let today = new Date();
        let yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

        let dateObj = new Date(date);

        if(dateObj.toDateString() === today.toDateString()) {
            prettyDate = "Today";
        } else if(dateObj.toDateString() === yesterday.toDateString()) {
            prettyDate = "Yesterday";
        } else {
            prettyDate = dateObj.toLocaleDateString();
        }

        return prettyDate;
    }


    const AnnouncementElement = (elementData) => {
        let initials = GetInitials(elementData.author.fName, elementData.author.lName);
        let initialsColor = GetInitialsColor(initials);

        if(initials === "ILI") {
            initialsColor = "#3573b9";
        }

        return (
            <Card key={elementData.adID}>
                <CardContent>
                    <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start'}}>
                        <Box sx={{width:"100%", display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                            <Box><Avatar sx={{ bgcolor: initialsColor }} aria-label="article">ML</Avatar></Box>
                            <Box sx={{width:'100%', isplay:'flex', flexDirection:'column', justifyContent:'center', alignContent:'center'}}>
                                <Typography variant="h5" color="text.primary" >{elementData.title}</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Typography variant="body2" color="text.secondary" sx={{textAlign:'left'}} pt={2} dangerouslySetInnerHTML={{__html: elementData.preview}} />
                        </Box>
                    </Box>
                    <Box sx={{width:"100%", display:'flex', flexDirection:'row', justifyContent:'space-between'}} mt={4}>
                        <Box><Typography variant="body2" color="text.secondary" sx={{textAlign:'left'}}>{elementData.author.fName} {elementData.author.lName}</Typography></Box>
                        <Box><Typography variant="body2" color="text.secondary" sx={{textAlign:'left'}}>{GetPrettyDate(elementData.date)}</Typography></Box>
                    </Box>
                </CardContent>

            </Card>
        );
    }

    const VideoElement = (elementData) => {
        let initials = GetInitials(elementData.author.fName, elementData.author.lName);
        let initialsColor = GetInitialsColor(initials);

        if(initials === "ILI") {
            initialsColor = "#3573b9";
        }


        let videoLink = elementData.link;
        
        let vimeo = false;
        //check if its a vimeo link or youtube
        if(videoLink.includes('vimeo')) {
            let vimeoID = videoLink.substring(videoLink.lastIndexOf('/') + 1);
            

            videoLink = "https://player.vimeo.com/video/" + vimeoID;
            vimeo = true;
        } else if(videoLink.includes('youtu')) {
            let youtubeID = videoLink.substring(videoLink.lastIndexOf('=') + 1);
            videoLink = "https://www.youtube.com/embed/" + youtubeID;
        } else {
            return null;
        }


        if(vimeo) {
            //vimeo embed
            let iframeHTML = "<iframe src=\"" + videoLink + "\" width=\"100%\" height=\"100%\" loading=\"lazy\" frameborder=\"0\" allow=\"fullscreen; picture-in-picture\" allowfullscreen></iframe>";
            return (
                <Card key={elementData.adID}>
                    <CardContent>
                        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start'}}>
                            <Box sx={{width:"100%", display:'flex', flexDirection:'row', justifyContent:'flex-start'}} mb={2}>
                                <Box><Avatar sx={{ bgcolor: initialsColor }} aria-label="article">ML</Avatar></Box>
                                <Box sx={{width:'100%', isplay:'flex', flexDirection:'column', justifyContent:'center', alignContent:'center'}}>
                                    <Typography variant="h6" color="text.primary" >{elementData.title}</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Box dangerouslySetInnerHTML={{__html: iframeHTML}} sx={{aspectRatio: "16/9", height:"100%", width:"100%"}} />
                            </Box>
                            <Box>
                                <Typography variant="body2" color="text.secondary" sx={{textAlign:'left'}} pt={2} dangerouslySetInnerHTML={{__html: elementData.preview}} />
                            </Box>
                        </Box>
                        <Box sx={{width:"100%", display:'flex', flexDirection:'row', justifyContent:'space-between'}} mt={4}>
                            <Box><Typography variant="body2" color="text.secondary" sx={{textAlign:'left'}}>{elementData.author.fName} {elementData.author.lName}</Typography></Box>
                            <Box><Typography variant="body2" color="text.secondary" sx={{textAlign:'left'}}>{GetPrettyDate(elementData.date)}</Typography></Box>
                        </Box>
                    </CardContent>
    
                </Card>
            );
        } else {

            let iframeHTML = '<iframe width="100%" height="100%" loading="lazy" src="' + videoLink + '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

            return (
                <Card key={elementData.adID}>
                    <CardContent>
                        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start'}}>
                            <Box sx={{width:"100%", display:'flex', flexDirection:'row', justifyContent:'flex-start'}} mb={2}>
                                <Box><Avatar sx={{ bgcolor: initialsColor }} aria-label="article">ML</Avatar></Box>
                                <Box sx={{width:'100%', isplay:'flex', flexDirection:'column', justifyContent:'center', alignContent:'center'}}>
                                    <Typography variant="h5" color="text.primary" >{elementData.title}</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Box dangerouslySetInnerHTML={{__html: iframeHTML}} sx={{aspectRatio: "16/9", height:"100%", width:"100%"}} />
                            </Box>
                            <Box>
                                <Typography variant="body2" color="text.secondary" sx={{textAlign:'left'}} pt={2} dangerouslySetInnerHTML={{__html: elementData.preview}} />
                            </Box>
                        </Box>
                        <Box sx={{width:"100%", display:'flex', flexDirection:'row', justifyContent:'space-between'}} mt={4}>
                            <Box><Typography variant="body2" color="text.secondary" sx={{textAlign:'left'}}>{elementData.author.fName} {elementData.author.lName}</Typography></Box>
                            <Box><Typography variant="body2" color="text.secondary" sx={{textAlign:'left'}}>{GetPrettyDate(elementData.date)}</Typography></Box>
                        </Box>
                    </CardContent>

                </Card>
            );
        }
    }

    return (
        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 900: 2}}>
            <Masonry gutter="10px">
                {BuildFeed(feedItems)}
            </Masonry>
        </ResponsiveMasonry>
        
    );
}


/*
<CardMedia
    component="div"
    sx={{height: "150px"}}
    
>
    <Box sx={{position:"relative",
        margin:"0 auto",
        backgroundImage: `url(${process.env.PUBLIC_URL + '/' + "water1.png"})`,
        backgroundRepeat  : 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize:"cover",
        width:"100%",
        height:"100%",
        justifyContent: "center"}}>

        <div style={{display:"flex", height:"100%", justifyContent: "center", alignItems:"center", flexDirection: "column"}}>
            <div>
                <Typography component="h1" variant="h3" style={{color:"white"}}>{elementData.title}</Typography>
            </div>
        </div>
    </Box>
</CardMedia>
*/

/*
                <CardHeader
                    avatar={
                    <Avatar sx={{ bgcolor: initialsColor }} aria-label="article">
                        {initials}
                    </Avatar>
                    }
                    title={elementData.title}
                />*/