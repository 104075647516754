import React, {useState, useEffect} from 'react';
import RDTeamsView from './RDETeams/RDTeamsView';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export default function RDETeams(props) {

    const [displayData, setDisplayData] = useState([{title: "Training Events", count: 0}, {title: "Leaders Trained", count: 0}]);


    return(
        <Box sx={{display:'flex', flexDirection:'column', height:'100%'}}>
            <Paper>
                <Box p={1}>
                    <Box sx={{height:'50px'}}>
                        <Typography variant="h4" component="h1" sx={{color:"grey"}} gutterBottom>
                            Teams
                        </Typography>
                    </Box>
                    <Box sx={{height:'300px'}}>
                        <RDTeamsView />
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}