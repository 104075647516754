import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { BarChart } from '@mui/x-charts/BarChart';

export default function RDLdrTrainedMethod(props) {

    const [displayData, setDisplayData] = useState([{title: "Training Events", count: 0}, {title: "Leaders Trained", count: 0}]);


    return(
        <Box sx={{display:'flex', flexDirection:'column', height:'100%'}}>
            <Paper>
                <Box p={1}>
                    <Box sx={{height:'50px'}}>
                        <Typography variant="h4" component="h1" sx={{color:"grey"}} gutterBottom>
                            Leaders Trained by Method
                        </Typography>
                    </Box>
                    <Box>
                        <BarChart
                            xAxis={[
                                {
                                id: 'barCategories',
                                data: ['2022', '2023', '2024', '2025'],
                                scaleType: 'band',
                                },
                            ]}
                            series={
                                [{ data: [100, 30, 20, 50], label:'In-Person' }, { data: [101, 120, 30, 27], label:'Online' }, { data: [20, 25, 46, 10], label:'Hybrid' }]
                            }
                            height={300}
                        />
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}